export default {
  category: "Data",
  name: "data-dictionary",
  title: "Data Dictionary",
  icon: "book",
  formControl: true,
  componentDefinition: {
    component: "data-dictionary",
    label: "Data Dictionary",
  },
}
