export default {
  category: "Chart",
  name: "pie",
  title: "Pie",
  icon: "pie-chart",
  componentDefinition: {
    component: "pie",
    label: "Pie Chart",
  },
}
