export default {
  category: "Form",
  name: "input",
  title: "Text Input",
  icon: "edit",
  formControl: true,
  componentDefinition: {
    component: "input",
    label: "Text",
  },
}
