export default {
  category: "Chart",
  name: "table",
  title: "Table",
  icon: "table",
  componentDefinition: {
    component: "table",
    columns: [],
    rowDetails: [],
  },
}
