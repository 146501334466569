"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var StrMap_1 = require("fp-ts/lib/StrMap");
var t = require("io-ts");
var StrMapType = /** @class */ (function (_super) {
    __extends(StrMapType, _super);
    function StrMapType(name, is, validate, serialize, type) {
        var _this = _super.call(this, name, is, validate, serialize) || this;
        _this.type = type;
        _this._tag = 'StrMapType';
        return _this;
    }
    return StrMapType;
}(t.Type));
exports.StrMapType = StrMapType;
/**
 * @example
 * import * as t from 'io-ts'
 * import { createStrMapFromDictionary } from 'io-ts-types/lib/fp-ts/createStrMapFromDictionary'
 * import { right } from 'fp-ts/lib/Either'
 * import { StrMap } from 'fp-ts/lib/StrMap'
 *
 * const T = createStrMapFromDictionary(t.number)
 *
 * assert.deepStrictEqual(T.decode({ someNumber: 42 }), right(new StrMap({ someNumber: 42 })))
 * assert.deepStrictEqual(T.encode(new StrMap({ someNumber: 42 })), { someNumber: 42 })
 */
exports.createStrMapFromDictionary = function (codec, name) {
    if (name === void 0) { name = "StrMap<" + codec.name + ">"; }
    var Dict = t.record(t.string, codec);
    return new StrMapType(name, function (m) { return m instanceof StrMap_1.StrMap && Object.keys(m.value).every(function (key) { return codec.is(m.value[key]); }); }, function (s, c) {
        var validation = Dict.validate(s, c);
        return validation.isLeft() ? validation : t.success(new StrMap_1.StrMap(validation.value));
    }, function (a) { return Dict.encode(a.value); }, codec);
};
