"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var t = require("io-ts");
var clone_1 = require("./clone");
/**
 * Returns a clone of the given codec which uses the given `validate` function
 *
 * @example
 * import { withValidate } from 'io-ts-types/lib/withValidate'
 * import * as t from 'io-ts'
 * import { PathReporter } from 'io-ts/lib/PathReporter'
 * import { right } from 'fp-ts/lib/Either'
 *
 * const T = withValidate(t.number, (u, c) => t.number.validate(u, c).map(n => n * 2))
 *
 * assert.deepStrictEqual(T.decode(1), right(2))
 * assert.deepStrictEqual(PathReporter.report(T.decode(null)), ['Invalid value null supplied to : number'])
 *
 * @since 0.4.3
 */
function withValidate(codec, validate, name) {
    if (name === void 0) { name = codec.name; }
    var r = clone_1.clone(codec);
    r.validate = validate;
    // tslint:disable-next-line: deprecation
    r.decode = function (i) { return validate(i, t.getDefaultContext(r)); };
    r.name = name;
    return r;
}
exports.withValidate = withValidate;
