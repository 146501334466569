export default {
  category: "Form",
  name: "password",
  title: "Password",
  icon: "lock",
  formControl: true,
  componentDefinition: {
    component: "password",
    label: "Password",
  },
}
