export default {
  category: "Data",
  name: "execute",
  title: "Execute",
  icon: "thunderbolt",
  componentDefinition: {
    component: "execute",
    hideLabel: true,
    components: [],
  },
}
