import { JSONRecord } from "globalTypes/JSONTypes"
import { isArray, isString, isObject } from "lodash/fp"
import jsonLogicSingleton from "./jsonLogicSingleton"
import { RulesLogic } from "json-logic-js"

/**
 *
 * @param rule
 * @param data
 * @return {*}
 */
function applyDeep(rule: JSONRecord, data: JSONRecord | JSONRecord[]) {
  // if data is an array, then iterate and apply
  if (isArray(data) && data.length > 0) {
    return data.map((dataItem) => {
      const ruleCopy = { ...rule }
      return _applyDeep(ruleCopy, dataItem)
    })
  }
  const ruleCopy = { ...rule }
  return _applyDeep(ruleCopy, data)
}

/**
 *
 * @private
 */
function _applyDeep(rule: JSONRecord, data: JSONRecord | JSONRecord[]) {
  const entries = Object.entries(rule)
  entries.forEach(([key, value]) => {
    /*
     * Is the value a jsonLogic rule?
     * Get the first key in the object, then test if the first key is a jsonLogic operator. */
    if (isObject(value) && jsonLogicSingleton().is_logic(value)) {
      const valueKey = jsonLogicSingleton().get_operator(value as RulesLogic)
      /*
       * Only process the supported jsonLogic commands */
      if (jsonLogicSingleton().operators.includes(valueKey)) {
        /*
         * Apply the jsonLogic to the data, and replace
         * the value (jsonLogic rule) with the results. */
        const nextValue: unknown = jsonLogicSingleton().apply(value as RulesLogic, { $: data })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        rule[key] = nextValue
      }
    }
  })
  return rule
}

/**
 *
 * @param data
 * @param separator
 * @return {*}
 */
function split(data: string, separator: string): string[][] {
  const ret = []
  if (isArray(data) && data.length > 0) {
    data.forEach((dataItem) => {
      if (isString(dataItem) && dataItem.length > 0) {
        ret.push(dataItem.split(separator))
      }
    })
  } else if (isString(data) && data.length > 0) {
    ret.push(data.split(separator))
  }
  return ret
}

/**
 *
 */
export default {
  split,
  applyDeep,
}
