export default {
  category: "Form",
  name: "form",
  title: "Form",
  icon: "form",
  componentDefinition: {
    component: "form",
    label: "Form",
    components: [],
  },
}
