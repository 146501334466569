export default {
  category: "Chart",
  name: "map",
  title: "Geo Map",
  icon: "global",
  componentDefinition: {
    component: "map",
    label: "Geo Map",
  },
}
