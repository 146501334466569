export default {
  category: "Display",
  name: "card",
  title: "Card",
  icon: "profile",
  componentDefinition: {
    component: "card",
    components: [],
  },
}
