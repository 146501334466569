export default {
  category: "Navigation",
  name: "route",
  title: "Route",
  icon: "deployment-unit",
  componentDefinition: {
    component: "route",
    components: [],
  },
}
