export default {
  category: "Navigation",
  name: "link",
  title: "Link",
  icon: "link",
  componentDefinition: {
    classNames: ["container"],
    component: "link",
    components: [],
  },
}
