export default {
  category: "Display",
  name: "column",
  title: "Columns",
  icon: "project",
  componentDefinition: {
    component: "column",
    components: [],
  },
}
