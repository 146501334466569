export default {
  category: "Navigation",
  name: "tab",
  title: "Tab",
  icon: "folder",
  componentDefinition: {
    component: "tab",
    components: [],
  },
}
