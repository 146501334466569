import React from "react"

export const QuoteIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = (/*props*/) => (
  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 512 512">
    <g>
      <g>
        <path d="M120,77C53.832,77,0,130.832,0,197c0,31.641,12.208,61.497,34.374,84.069c21.458,21.851,49.904,34.501,80.375,35.812    c0.144,1.904,0.251,4.528,0.251,8.119c0,14.712-11.188,59.665-18.241,84.544L89.542,435h87.584l5.867-9.453    C185.32,421.797,240,332.148,240,197C240,130.832,186.168,77,120,77z M154.189,395h-12.13C147.806,372.416,155,340.959,155,325    c0-12.664-0.94-22.838-5.531-31.33c-4.089-7.564-12.641-16.573-30.183-16.669l-0.873-0.022C75.176,276.141,40,240.263,40,197    c0-44.112,35.888-80,80-80s80,35.888,80,80C200,296.32,167.164,369.834,154.189,395z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M392,77c-66.168,0-120,53.832-120,120c0,31.641,12.208,61.497,34.374,84.069c21.458,21.851,49.904,34.501,80.375,35.812    c0.144,1.904,0.251,4.528,0.251,8.119c0,14.712-11.188,59.665-18.241,84.544L361.542,435h87.584l5.867-9.453    C457.32,421.797,512,332.148,512,197C512,130.832,458.168,77,392,77z M426.189,395h-12.13C419.806,372.416,427,340.959,427,325    c0-12.664-0.94-22.838-5.531-31.33c-4.089-7.564-12.641-16.573-30.183-16.669l-0.873-0.022C347.176,276.141,312,240.263,312,197    c0-44.112,35.888-80,80-80s80,35.888,80,80C472,296.32,439.164,369.834,426.189,395z" />
      </g>
    </g>
  </svg>
)
