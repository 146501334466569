export default {
  category: "Display",
  name: "modal",
  title: "Modal",
  icon: "select",
  componentDefinition: {
    component: "modal",
    components: [],
  },
}
