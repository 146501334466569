export default {
  category: "Form",
  name: "toggle",
  title: "Toggle",
  icon: "login",
  formControl: true,
  componentDefinition: {
    component: "toggle",
    label: "Toggle",
  },
}
