export default {
  category: "Form",
  name: "textarea",
  title: "Text Area",
  icon: "edit",
  formControl: true,
  componentDefinition: {
    component: "textarea",
    label: "Text Area",
  },
}
