export default {
  category: "Data",
  name: "query",
  title: "Query",
  icon: "database",
  componentDefinition: {
    component: "query",
    hideLabel: true,
    components: [],
  },
}
