export default {
  category: "Display",
  name: "container",
  title: "Container",
  icon: "layout",
  componentDefinition: {
    classNames: ["container"],
    component: "container",
    components: [],
  },
}
