export default {
  category: "Display",
  name: "collapse",
  title: "Collapse",
  icon: "vertical-align-middle",
  componentDefinition: {
    component: "collapse",
    sections: [],
  },
}
