export default {
  category: "Navigation",
  name: "relationships",
  title: "Relationships",
  icon: "deployment-unit",
  componentDefinition: {
    component: "relationships",
    hideLabel: true,
    components: [],
  },
}
