export default {
  category: "Display",
  name: "repeater",
  title: "Repeater",
  icon: "unordered-list",
  componentDefinition: {
    component: "repeater",
    components: [],
  },
}
