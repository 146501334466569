export default {
  category: "Form",
  name: "number-range",
  title: "Number Range",
  icon: "control",
  formControl: true,
  componentDefinition: {
    component: "number-range",
    label: "Number Range",
  },
}
