export default {
  category: "Form",
  name: "time-range",
  title: "Time Range",
  icon: "clock-circle",
  formControl: true,
  componentDefinition: {
    component: "time-range",
    label: "Time Range",
  },
}
