import React from "react"

export const routes = {
  login: {
    abs: "/login",
    component: React.lazy(() => import("../../views/login")),
    description: "",
    title: "Home",
    iconType: "home",
    path: "login",
    redirectFrom: ["/"],
    requiresAuthentication: false as const,
    subroutes: {},
  },
  // home: {
  //   abs: "/app/home",
  //   component: null,
  //   description: "OnPoint Global reports and admin",
  //   title: "OnPoint Global Business",
  //   iconType: "home",
  //   path: "default",
  //   redirectFrom: [],
  //   requiresAuthentication: true as const,
  // },
  globalConfig: {
    abs: "/app/admin/global-config",
    component: null,
    description: "Manage GlobalConfig.Config entries",
    title: "Global Config",
    iconType: "code",
    path: "global-config",
    redirectFrom: [],
    requiresAuthentication: true as const,
    subroutes: {
      "/": {
        abs: "/app/admin/global-config",
        component: null,
        description: "",
        title: "Global Configs Index",
        iconType: "code",
        path: "/",
        redirectFrom: [],
        requiresAuthentication: true as const,
        subroutes: {},
      },
      create: {
        abs: "/app/admin/global-config/create",
        component: null,
        description: "",
        title: "Create Global Config",
        iconType: "code",
        path: "create",
        redirectFrom: [],
        requiresAuthentication: true as const,
        subroutes: {},
      },
      ":configId/edit": {
        abs: "/app/admin/global-config/:configId/edit",
        component: null,
        description: "",
        title: "Edit Global Config",
        iconType: "code",
        path: ":configId/edit",
        redirectFrom: [],
        requiresAuthentication: true as const,
        subroutes: {},
      },
      ":configId": {
        abs: "/app/admin/global-config/:configId",
        component: null,
        description: "",
        title: "Global Configs Index",
        iconType: "code",
        path: ":configId",
        redirectFrom: [],
        requiresAuthentication: true as const,
        subroutes: {},
      },
    },
  },
}
