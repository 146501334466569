export default {
  category: "Display",
  name: "icon",
  title: "Icon",
  icon: "question-circle",
  componentDefinition: {
    classNames: ["container"],
    component: "icon",
    components: [],
  },
}
