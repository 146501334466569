export default {
  category: "Navigation",
  name: "tab-set",
  title: "Tab Set",
  icon: "folder",
  componentDefinition: {
    component: "tab-set",
    tabs: [],
  },
}
