export default {
  category: "Display",
  name: "remote-component",
  title: "Component",
  icon: "select",
  componentDefinition: {
    component: "remote-component",
    label: "Component",
  },
}
