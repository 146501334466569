export default {
  category: "Form",
  name: "select",
  title: "Select",
  icon: "bars",
  formControl: true,
  componentDefinition: {
    component: "select",
    label: "Select",
  },
}
