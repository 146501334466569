import React from "react"

export const ThermometerIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = (/*props*/) => (
  <svg fill="currentColor" width="1em" height="1em" viewBox="0 0 512 512">
    <g>
      <g>
        <g>
          <path
            d="M270.403,313.103V74.611C270.403,33.47,236.934,0,195.792,0c-41.14,0-74.61,33.47-74.61,74.611v238.492
c-24.481,21.43-38.844,52.701-38.844,85.443c0,62.56,50.896,113.454,113.454,113.454c62.56,0,113.455-50.895,113.455-113.454
C309.247,365.804,294.884,334.533,270.403,313.103z M158.28,74.611c0-20.686,16.828-37.513,37.512-37.513
c20.686,0,37.513,16.828,37.513,37.513v132.693H158.28V74.611z M195.792,474.902c-42.102,0-76.356-34.253-76.356-76.356
c0-24.218,11.688-47.248,31.265-61.606c4.765-3.495,7.579-9.049,7.579-14.958v-77.579h75.026v77.579
c0,5.907,2.815,11.463,7.578,14.958c19.578,14.358,31.265,37.389,31.265,61.606C272.149,440.649,237.896,474.902,195.792,474.902
z"
          />
          <path
            d="M342.887,88.22h68.227c10.244,0,18.549-8.305,18.549-18.549s-8.305-18.549-18.549-18.549h-68.227
c-10.244,0-18.549,8.305-18.549,18.549S332.643,88.22,342.887,88.22z"
          />
          <path
            d="M342.887,166.311h43.298c10.244,0,18.549-8.305,18.549-18.549s-8.305-18.549-18.549-18.549h-43.298
c-10.244,0-18.549,8.305-18.549,18.549S332.643,166.311,342.887,166.311z"
          />
          <path
            d="M342.887,244.403h43.298c10.244,0,18.549-8.305,18.549-18.549c0-10.244-8.305-18.549-18.549-18.549h-43.298
c-10.244,0-18.549,8.305-18.549,18.549C324.338,236.098,332.643,244.403,342.887,244.403z"
          />
          <path
            d="M411.113,285.396h-68.227c-10.244,0-18.549,8.305-18.549,18.549c0,10.244,8.305,18.549,18.549,18.549h68.227
c10.244,0,18.549-8.305,18.549-18.549C429.662,293.701,421.357,285.396,411.113,285.396z"
          />
        </g>
      </g>
    </g>
  </svg>
)
