"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Returns a clone of the given codec
 *
 * @example
 * import { clone } from 'io-ts-types/lib/clone'
 * import * as t from 'io-ts'
 *
 * assert.deepStrictEqual(clone(t.string), t.string)
 *
 * @since 0.4.3
 */
function clone(t) {
    var r = Object.create(Object.getPrototypeOf(t));
    Object.assign(r, t);
    return r;
}
exports.clone = clone;
