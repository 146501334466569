export default {
  category: "Navigation",
  name: "router",
  title: "Router",
  icon: "deployment-unit",
  componentDefinition: {
    component: "router",
    components: [],
  },
}
