export default {
  category: "Form",
  name: "tags",
  title: "Tags",
  icon: "tags",
  formControl: true,
  componentDefinition: {
    component: "tags",
    label: "Tags",
  },
}
