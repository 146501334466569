export default {
  category: "Form",
  name: "radio",
  title: "Radio",
  icon: "check-circle",
  formControl: true,
  componentDefinition: {
    component: "radio",
    label: "Radio",
  },
}
