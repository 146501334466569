export default {
  category: "Form",
  name: "@opg/interface-builder-plugins_bulk-text-input",
  title: "Bulk Text",
  icon: "copy",
  formControl: true,
  componentDefinition: {
    component: "bulk-text-input",
    label: "Bulk Text",
  },
}
