"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var array = require("fp-ts/lib/Array");
/* tslint:enable no-unused-variable */
var jsToString = function (value) { return (value === undefined ? 'undefined' : JSON.stringify(value)); };
exports.formatValidationError = function (error) {
    var path = error.context
        .map(function (c) { return c.key; })
        .filter(function (key) { return key.length > 0; })
        .join('.');
    // The actual error is last in context
    var maybeErrorContext = array.last(
    // https://github.com/gcanti/fp-ts/pull/544/files
    error.context);
    return maybeErrorContext.map(function (errorContext) {
        var expectedType = errorContext.type.name;
        return (
        // https://github.com/elm-lang/core/blob/18c9e84e975ed22649888bfad15d1efdb0128ab2/src/Native/Json.js#L199
        // tslint:disable-next-line:prefer-template
        "Expecting " + expectedType
            + (path === '' ? '' : " at " + path)
            + (" but instead got: " + jsToString(error.value) + "."));
    });
};
exports.reporter = function (validation) { return (validation.fold(function (errors) { return array.catOptions(errors.map(exports.formatValidationError)); }, function () { return []; })); };
