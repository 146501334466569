export default {
  category: "Display",
  name: "ordered-list",
  title: "Ordered List",
  icon: "ordered-list",
  componentDefinition: {
    component: "ordered-list",
    components: [],
  },
}
