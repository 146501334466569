export default {
  category: "Data",
  name: "data-map",
  title: "Data Map",
  icon: "appstore",
  formControl: true,
  componentDefinition: {
    component: "data-map",
    label: "Data Map",
  },
}
