export default {
  category: "Data",
  name: "query-builder",
  title: "Query Builder",
  icon: "search",
  formControl: false,
  componentDefinition: {
    component: "query-builder",
    label: "Query Builder",
  },
}
