export default {
  category: "Navigation",
  name: "sectioned-navigation",
  title: "Sectioned Nav",
  icon: "more",
  componentDefinition: {
    component: "sectioned-navigation",
    sections: [],
  },
}
