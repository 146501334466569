export default {
  category: "Chart",
  name: "line-chart",
  title: "Line Chart",
  icon: "line-chart",
  componentDefinition: {
    component: "line-chart",
    label: "Line Chart",
  },
}
