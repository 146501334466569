import { ObjectProperty, SupportedTypes } from "types"
import { isNull, isNumber, isString, isUndefined, reduce, toNumber } from "lodash"
import { JSONRecord, JSONType } from "@opg/interface-builder"
import { v4 as uuid } from "uuid"

/**
 * Decode JSONRecord
 * @param objectProperties
 */
export function objectPropertiesToJSONRecord(objectProperties: ObjectProperty[]): JSONRecord | undefined {
  if (objectProperties.length === 0) return
  return reduce(
    objectProperties,
    (acc, { key, value }) => {
      if (key) {
        return { ...acc, [key]: value }
      }
      return acc
    },
    {}
  )
}

/**
 * Encode JSONRecord
 * @param jsonRecord
 */
export function jsonRecordToObjectProperties(jsonRecord: JSONRecord | undefined): ObjectProperty[] | undefined {
  if (!jsonRecord) return
  const entries = Object.entries(jsonRecord)
  const objectPropertiesDictionary: ObjectProperty[] = entries.map<ObjectProperty>(([key, value]) => ({
    key,
    value,
    id: uuid(),
  }))
  return Object.values(objectPropertiesDictionary)
}

export function toString(value: JSONType | undefined): string | null {
  if (isNull(value) || isUndefined(value)) return null
  if (isString(value)) return value
  return JSON.stringify(value)
}

export function toBoolean(value: JSONType | undefined): boolean {
  if (isNull(value) || isUndefined(value)) return false
  if (isString(value)) return value === "true" || value === "1"
  if (isNumber(value)) return value === 1
  return !!value
}

export function getType(value: JSONType | undefined): SupportedTypes {
  if (isNull(value) || isUndefined(value)) return "string"
  if (isNumber(value)) return "number"
  if (typeof value === "boolean") return "boolean"
  return "string"
}

export function coerceType(supportedType: SupportedTypes, value: ObjectProperty["value"]): ObjectProperty["value"] {
  switch (supportedType) {
    case "boolean":
      return toBoolean(value)
    case "number":
      return toNumber(value)
    default:
      return toString(value)
  }
}
