import jsonLogic, { RulesLogic } from "json-logic-js"
import opg from "components/RenderComponents/ComponentDecorators/withDataBinding/opg"
import lodash from "lodash"

export type Operator =
  | "=="
  | "==="
  | "!="
  | "!=="
  | ">"
  | ">="
  | "<"
  | "<="
  | "!!"
  | "!"
  | "%"
  | "log"
  | "in"
  | "cat"
  | "substr"
  | "+"
  | "*"
  | "-"
  | "/"
  | "min"
  | "max"
  | "merge"
  | "var"
  | "missing"
  | "missing_some"
  | "opg.split"
  | "opg.applyDeep"

const operators: Operator[] = [
  "==",
  "===",
  "!=",
  "!==",
  ">",
  ">=",
  "<",
  "<=",
  "!!",
  "!",
  "%",
  "log",
  "in",
  "cat",
  "substr",
  "+",
  "*",
  "-",
  "/",
  "min",
  "max",
  "merge",
  "var",
  "missing",
  "missing_some",
  "opg.split",
  "opg.applyDeep",
]

/**
 * The @types/json-logic-js definition is incomplete, so we provide a full interface
 */
interface JsonLogic {
  add_operation: (name: string, code: (...args: unknown[]) => unknown) => void

  apply: (logic: RulesLogic, data?: unknown) => unknown

  get_operator: (logic: RulesLogic) => Operator

  get_values: (logic: RulesLogic) => RulesLogic

  /**
   * An object that is not null, not an array, with exactly one key.
   * Does not check if key is a valid JsonLogic operator!
   * @param rules
   */
  is_logic: (rules: unknown) => boolean

  rm_operation: (name: string) => void

  rule_like: (rule: unknown, pattern: string) => boolean

  /**
   * This helper will defer to the JsonLogic spec as a tie-breaker when different language interpreters define different behavior for the truthiness of primitives.  E.g., PHP considers empty arrays to be falsy, but Javascript considers them to be truthy. JsonLogic, as an ecosystem, needs one consistent answer.
   * Spec and rationale here: http://jsonlogic.com/truthy
   * @param value
   */
  truthy: (value?: unknown) => boolean

  uses_data: (logic: RulesLogic) => unknown[]

  operators: Operator[]
}

let _jsonLogic: JsonLogic

export default function jsonLogicSingleton(): JsonLogic {
  if (!_jsonLogic) {
    jsonLogic.add_operation("_", lodash)
    jsonLogic.add_operation("opg.split", opg.split)
    jsonLogic.add_operation("opg.applyDeep", opg.applyDeep)
    _jsonLogic = jsonLogic as JsonLogic
    _jsonLogic.operators = operators
  }
  return _jsonLogic
}
