export default {
  category: "Data",
  name: "upload",
  title: "Upload",
  icon: "upload",
  formControl: true,
  componentDefinition: {
    component: "upload",
    label: "Upload",
  },
}
