export default {
  category: "Form",
  name: "empty",
  title: "Empty",
  icon: "file-unknown",
  componentDefinition: {
    component: "empty",
    label: "Empty",
  },
}
