import React from "react"
import layoutDefinition from "./layoutDefinition"
import { BaseInterfaceComponent, JSONRecord, LayoutDefinition } from "@opg/interface-builder"
import { DataDictionaryInterfaceComponentProps } from "types"
import { DisplayMode } from "./components/DisplayMode"
import { settings } from "./settings"
import { isEmpty, isUndefined } from "lodash/fp"

export default class DataDictionaryInterfaceComponent extends BaseInterfaceComponent<DataDictionaryInterfaceComponentProps> {
  static defaultProps = {
    keyLabel: "Key",
    valueLabel: "Value",
    valueComponent: [
      {
        hideLabel: false,
        label: "Label",
        component: "input",
        valueKey: "value",
      },
    ],
    valueKey: "data",
  }

  static getLayoutDefinition(): LayoutDefinition {
    return layoutDefinition
  }

  static manageForm = settings

  handleChangeData = (nextState: JSONRecord[]): void => {
    this.setValue([this.props.valueKey, nextState])
  }

  render(): JSX.Element {
    const data = this.getValue(this.props.valueKey) || this.props.defaultValue

    return (
      <DisplayMode
        data={data}
        fullWidth={this.props.fullWidth}
        getRootUserInterfaceData={this.props.getRootUserInterfaceData}
        incomingEventHandlers={this.props.incomingEventHandlers}
        keyLabel={this.props.keyLabel}
        onChangeData={this.handleChangeData}
        onChangeRootData={this.props.onChangeRootData}
        outgoingEventMap={this.props.outgoingEventMap}
        valueComponent={this.props.valueComponent}
        valueLabel={this.props.valueLabel}
      />
    )
  }
}
