export default {
  category: "Data",
  name: "slot-config",
  title: "Slot Config",
  icon: "border-outer",
  formControl: true,
  componentDefinition: {
    component: "slot-config",
    label: "Slot Config",
  },
}
