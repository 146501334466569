export default {
  category: "Data",
  name: "dev-tools",
  title: "Dev Tools",
  icon: "setting",
  description: ``,
  componentDefinition: {
    component: "dev-tools",
    components: [],
  },
}
