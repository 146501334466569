export default {
  category: "Data",
  name: "download",
  title: "Download",
  icon: "download",
  formControl: true,
  componentDefinition: {
    component: "download",
  },
}
