export default {
  category: "Form",
  name: "color-picker",
  title: "Color",
  icon: "bg-colors",
  formControl: true,
  componentDefinition: {
    component: "color-picker",
    label: "Color",
  },
}
