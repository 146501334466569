export default {
  category: "Data",
  name: "data-injector",
  title: "Data Injector",
  icon: "import",
  description: ``,
  componentDefinition: {
    component: "data-injector",
    components: [],
  },
}
