import { ObjectProperty } from "types"
import { isBoolean, isEmpty, isNull, isNumber, isString, isUndefined } from "lodash"
import { Input, InputNumber, Switch } from "antd"
import React from "react"

export function ValueInput(props: {
  onChange: (index: number, objectProperty: ObjectProperty) => void
  index: number
  objectProperty: ObjectProperty
  tabIndex: number
}): JSX.Element | null {
  if (
    isString(props.objectProperty.value) ||
    isNull(props.objectProperty.value) ||
    isUndefined(props.objectProperty.value)
  ) {
    return (
      <Input
        onChange={(e) => {
          !isNaN(props.index) && props.onChange(props.index, { ...props.objectProperty, value: e.target.value })
        }}
        size="default"
        placeholder="Enter a value"
        tabIndex={props.tabIndex}
        disabled={isEmpty(props.objectProperty.key)}
        value={props.objectProperty.value || undefined}
      />
    )
  }
  if (isNumber(props.objectProperty.value)) {
    return (
      <InputNumber
        onChange={(e) => {
          !isNaN(props.index) && props.onChange(props.index, { ...props.objectProperty, value: e || 0 })
        }}
        size="default"
        tabIndex={props.tabIndex}
        value={props.objectProperty.value}
        disabled={isEmpty(props.objectProperty.key)}
      />
    )
  }
  if (isBoolean(props.objectProperty.value)) {
    return (
      <div tabIndex={props.tabIndex}>
        <Switch
          onChange={(e) => {
            !isNaN(props.index) && props.onChange(props.index, { ...props.objectProperty, value: e || false })
          }}
          size="default"
          checked={props.objectProperty.value}
          disabled={isEmpty(props.objectProperty.key)}
        />
      </div>
    )
  }
  return null
}
