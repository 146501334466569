export default {
  category: "Navigation",
  name: "wizard",
  title: "Wizard",
  icon: "thunderbolt",
  componentDefinition: {
    component: "wizard",
    steps: [],
  },
}
