export default {
  category: "Display",
  name: "user-interface",
  title: "User Interface",
  icon: "build",
  componentDefinition: {
    component: "user-interface",
    label: "User Interface",
  },
}
