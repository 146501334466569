export default {
  category: "Display",
  name: "iframe",
  title: "IFrame",
  icon: "global",
  componentDefinition: {
    component: "iframe",
    components: [],
  },
}
