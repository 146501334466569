import React from "react"
import { Button, Form, Input, Select } from "antd"
import { DataPathContext } from "@opg/interface-builder"
import { DataFormProps, ObjectProperty, SupportedTypes } from "types"
import styles from "../styles.scss"
import { coerceType, getType, jsonRecordToObjectProperties, objectPropertiesToJSONRecord } from "codec"
import { isNull, isEmpty } from "lodash"
import { v4 as uuid } from "uuid"
import { ValueInput } from "./ValueInput"

export function DisplayMode(props: DataFormProps) {
  const { onChangeData } = props
  const [activeKey, setActiveKey] = React.useState<{ key: string | null; id: string; isValid: boolean }>({
    key: null,
    id: uuid(),
    isValid: true,
  })

  /**
   * Convert the userInterfaceData JSONRecord to ObjectProperty[]
   */
  const objectProperties: ObjectProperty[] | undefined = React.useMemo(() => {
    const objectProperties = jsonRecordToObjectProperties(props.data) || []
    if (objectProperties.length === 0 || !isNull(objectProperties[objectProperties.length - 1].key)) {
      objectProperties.push({ key: null, value: null, id: uuid() })
    }
    return objectProperties
  }, [props.data])

  /**
   * Update objectProperty in collection
   * @param index
   * @param nextItemDataState
   */
  const handleChange = React.useCallback(
    (index: number, objectProperty: ObjectProperty) => {
      if (!objectProperties || !onChangeData) return
      const isValidKey = uniqueKey(index, objectProperty, objectProperties)
      setActiveKey({ key: objectProperty.key, id: objectProperty.id, isValid: isValidKey })
      if (isValidKey && objectProperty.key) {
        //
        // Update objectProperty in objectProperties
        const beforeItems = objectProperties.slice(0, index)
        const afterItems = objectProperties.slice(index + 1)
        const nextObjectProperties = [...beforeItems, objectProperty, ...afterItems]

        // Update userInterfaceData
        const nextDataDictionary = objectPropertiesToJSONRecord(nextObjectProperties)
        onChangeData(nextDataDictionary)
      }
    },
    [objectProperties, onChangeData]
  )

  /**
   * Delete objectProperty from collection
   * @param objectProperty
   * @param index
   */
  const handleDelete = React.useCallback(
    (index: number) => {
      if (!objectProperties || !onChangeData) return

      // Splice objectProperties
      const beforeItems = objectProperties.slice(0, index)
      const afterItems = objectProperties.slice(index + 1)
      const nextObjectProperties = [...beforeItems, ...afterItems]

      // Update userInterfaceData
      const nextDataDictionary = objectPropertiesToJSONRecord(nextObjectProperties)
      onChangeData(nextDataDictionary)
    },
    [objectProperties, onChangeData]
  )

  return (
    <div className={styles.container} style={{ display: props.fullWidth ? "grid" : "inline-grid" }}>
      <div className={styles.colHead}>{props.keyLabel}</div>
      <div className={styles.colHead}>Type</div>
      <div className={styles.colHead}>&nbsp;</div>
      <div className={styles.colHead}>{props.valueLabel}</div>
      <div className={styles.colHead}>&nbsp;</div>
      {objectProperties &&
        objectProperties.map((objectProperty, index) => (
          <React.Fragment key={`data-item-${index}`}>
            <div className={styles.key}>
              {/* ******************************************
               *
               * KEY INPUT
               */}
              <DataPathContext path="keyComponent">
                <Form.Item
                  validateStatus={
                    activeKey.id === objectProperty.id ? (activeKey.isValid ? undefined : "error") : undefined
                  }
                  help={
                    activeKey.id === objectProperty.id
                      ? activeKey.isValid
                        ? undefined
                        : "Key name must be unique"
                      : undefined
                  }>
                  <Input
                    onChange={(e) => {
                      handleChange(index, { ...objectProperty, key: e.target.value })
                    }}
                    size="default"
                    placeholder="Enter a key name"
                    tabIndex={index * 10 + 1}
                    value={
                      activeKey.id === objectProperty.id ? activeKey.key || undefined : objectProperty.key || undefined
                    }
                  />
                </Form.Item>
              </DataPathContext>
            </div>
            <div className={styles.type}>
              {/* ******************************************
               *
               * TYPE SELECTOR
               */}
              <Select
                defaultValue={getType(objectProperty.value)}
                style={{ width: 100 }}
                disabled={isEmpty(objectProperty.key)}
                onChange={(e: SupportedTypes) => {
                  const nextValue = coerceType(e, objectProperty.value)
                  handleChange(index, { ...objectProperty, value: nextValue })
                }}>
                <Select.Option value="string">string</Select.Option>
                <Select.Option value="number">number</Select.Option>
                <Select.Option value="boolean">boolean</Select.Option>
              </Select>
            </div>
            <div className={styles.colon} style={{ fontSize: "18px", lineHeight: "30px" }}>
              {":"}
            </div>
            <div className={styles.value}>
              {/* ******************************************
               *
               * VALUE INPUT
               */}
              <DataPathContext path="valueComponent">
                <ValueInput
                  onChange={handleChange}
                  index={index}
                  tabIndex={index * 10 + 3}
                  objectProperty={objectProperty}
                />
              </DataPathContext>
            </div>
            <div className={styles.tools}>
              {/* ******************************************
               *
               * TOOLS
               */}
              {objectProperty.key === null ? (
                <div style={{ width: 24 }}>&nbsp;</div>
              ) : (
                <Button
                  disabled={objectProperties.length === index}
                  icon="close"
                  size="small"
                  onClick={(): void => handleDelete(index)}
                  type="link"
                  style={{ color: "red" }}
                />
              )}
            </div>
          </React.Fragment>
        ))}
    </div>
  )
}

DisplayMode.defaultProps = {
  data: [],
  keyLabel: "key",
  valueLabel: "value",
}

/**
 * Key name must be unique
 */
const uniqueKey = (index: number, objectProperty: ObjectProperty, objectProperties: ObjectProperty[]): boolean => {
  if (!objectProperties) return true
  const hasDuplicateKey = objectProperties.find((item, idx) => item.key === objectProperty.key && idx !== index)
  return !hasDuplicateKey
}
